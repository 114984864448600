import React, { useState } from "react";
import ReactMarkdown from 'react-markdown';
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo";
import Footer from "../components/_App/Footer";
import * as Icon from 'react-feather';
import NavbarStyleThree from "../components/_App/NavbarStyleThree";
import { Link, graphql } from "gatsby";
import Loadable from '@loadable/component';
import { formatDate, getImageURL } from "../format-date";
import FAQ from "../components/faq";
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))
const options = {
    loop: false,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    items: 1,
    animateOut: 'fadeOut',
}



const ProductDetails = ({ data }) => {
    const productInfo = data.strapiProducts;
    // const [selectedLicense, setSelectedLicense] = useState(productInfo.license_details[0]);
    // function licenseSelectionEvent(license) {
    //     setSelectedLicense(license);
    // }
    productInfo.product_images.forEach(image => {
        image.srcset = "";
        image.sizes = "";
        image.srcset += `${getImageURL(image.localFile.name, image.localFile.extension, 300)} 300w,`;
        image.srcset += `${getImageURL(image.localFile.name, image.localFile.extension, 400)} 450w,`;
        image.srcset += `${getImageURL(image.localFile.name, image.localFile.extension, 500)} 600w,`;
        image.srcset += `${getImageURL(image.localFile.name, image.localFile.extension, 600)} 600w,`;
        image.srcset += `${getImageURL(image.localFile.name, image.localFile.extension, 800)} 800w,`;
        image.srcset += `${getImageURL(image.localFile.name, image.localFile.extension, 1000)} 1200w,`;
        image.sizes += `(max-width: 1200px) 40vw, 1200px`;
    });
    productInfo.related_products.forEach(product => {
        product.srcset = "";
        product.sizes = "";
        product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 300)} 300w,`;
        product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 450)} 450w,`;
        product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 500)} 600w,`;
        product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 600)} 600w,`;
        product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 800)} 800w,`;
        product.srcset += `${getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension, 1200)} 1200w,`;
        product.sizes += `(max-width: 1200px) 40vw, 1200px`;
    });
    const [isLightBoxOpen, setLightBoxDisplayState] = useState(false);

    function handleLightBoxImagePopup(status) {
        setLightBoxDisplayState(status);
        console.log(isLightBoxOpen);
    }

    function LinkRenderer(props) {
        return (
            <a href={props.href} target="_blank" rel="noreferrer">
                {props.children}
            </a>
        );
    }

    function addImageLazyLoading(props){
        return (
          <img srcSet={props.src} alt={props.alt} loading="lazy"></img>
        )
    }

    return (
        <>
            <Layout>
                <SEO title={productInfo.seo_title} type="website" product_details={productInfo} url={`products/${productInfo.slug}/`} description={productInfo.seo_description} image={getImageURL(productInfo.seo_image.localFile.name, productInfo.seo_image.localFile.extension)} />
                <div className="">
                    <NavbarStyleThree />
                    <br /><br />
                    <br /><br /><br />
                    <div className="product-details-area pt-100">
                        <div className="container-fluid padd-2">

                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    {/* <span className="sub-title">Our Solutions</span> */}
                                    <h1 className="font-1rem">{productInfo.product_name}</h1>
                                    {/* <div className="bar"></div> */}
                                    {productInfo.short_description ? <p>{productInfo.short_description}</p> : ''}
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-4">
                                            <Link to={productInfo.gumroad_purchase_link} target="_blank" className="btn buy-btn width-100">
                                                Purchase
                                            </Link>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 mt-10">
                                            <Link to={productInfo.preview_url} target="_blank" className="btn btn-primary-outline width-100">
                                                Live Preview
                                            </Link>
                                        </div>
                                        {/* {productInfo.info_graphic_image ?
                                            <div onClick={() => handleLightBoxImagePopup(true)} className="col-lg-4 col-sm-4 mt-10">
                                                <Link target="_blank" className="btn btn-pink width-100">
                                                    Preview
                                                </Link>
                                            </div> : ''
                                        } */}

                                        {/* {isLightBoxOpen && (<Lightbox image={getImageURL(productInfo.info_graphic_image.localFile.name, productInfo.info_graphic_image.localFile.extension)} zoomStep="1" onClose={() => handleLightBoxImagePopup(false)} />)} */}
                                    </div>
                                    <br /><br />



                                    <div className="border">
                                        <div className="row bottom-borderr mx-0 px-2 pt5 pb3">
                                            <div className="col-lg-4 col-sm-5 col-5">
                                                <p>Price</p>
                                            </div>
                                            <div className="col-lg-1 col-sm-1 col-1">
                                                <p> :</p>
                                            </div>
                                            <div className="col-lg-7 col-sm-6 col-6">
                                                <p className="color-black"> ${productInfo.product_price}</p>
                                            </div>
                                        </div>
                                        {/* <br /> */}
                                        {/* <div className="row bottom-borderr mx-0 px-2 pb3 pt5">
                                            <div className="col-lg-4 col-sm-5 col-5">
                                                <p>Released On</p>
                                            </div>
                                            <div className="col-lg-1 col-sm-1 col-1">
                                                <p> :</p>
                                            </div>
                                            <div className="col-lg-7 col-sm-6 col-6">
                                                <p className="color-black"> {formatDate(productInfo.release_date, 'MMMM DD YYYY')}</p>
                                            </div>
                                        </div> */}
                                        {/* <br /> */}
                                        {/* <div className="row bottom-borderr mx-0 px-2 pb-2">
                                        <div className="col-lg-4 col-sm-5 col-5">
                                            <p>Version</p>
                                        </div>
                                        <div className="col-lg-1 col-sm-1 col-1">
                                            <p> :</p>
                                        </div>
                                        <div className="col-lg-7 col-sm-6 col-6">
                                            <h6> {productInfo.version}</h6>
                                        </div>
                                    </div>
                                    <br /> */}
                                        {productInfo.last_updated_at ?
                                            <div className="row bottom-borderr mx-0 px-2 pb3 pt5">
                                                <div className="col-lg-4 col-sm-5 col-5">
                                                    <p>Last Updated</p>
                                                </div>
                                                <div className="col-lg-1 col-sm-1 col-1">
                                                    <p> :</p>
                                                </div>
                                                <div className="col-lg-7 col-sm-6 col-6">
                                                    <p className="color-black"> {formatDate(productInfo.last_updated_at, 'MMMM DD YYYY')}</p>
                                                </div>
                                            </div> : ''
                                        }
                                        {/* <br /> */}
                                        <div className="row bottom-borderr mx-0 px-2 pb3 pt5">
                                            <div className="col-lg-4 col-sm-5 col-5">
                                                <p>Files Included</p>
                                            </div>
                                            <div className="col-lg-1 col-sm-1 col-1">
                                                <p> :</p>
                                            </div>
                                            <div className="col-lg-7 col-sm-6 col-6">
                                                <p className="color-black"> {productInfo.files_included}</p>
                                            </div>
                                        </div>
                                        {/* <br /> */}
                                        {/* <div className="row bottom-borderr mx-0 px-2 pb-2">
                                        <div className="col-lg-4 col-sm-5 col-5">
                                            <p>File Size</p>
                                        </div>
                                        <div className="col-lg-1 col-sm-1 col-1">
                                            <p> :</p>
                                        </div>
                                        <div className="col-lg-7 col-sm-6 col-6">
                                            <h6> {productInfo.file_size}</h6>
                                        </div>
                                    </div>
                                    <br /> */}
                                        <div className="row bottom-borderr mx-0 px-2 pb3 pt5">
                                            <div className="col-lg-4 col-sm-5 col-5">
                                                <p>Compatibility</p>
                                            </div>
                                            <div className="col-lg-1 col-sm-1 col-1">
                                                <p> :</p>
                                            </div>
                                            <div className="col-lg-7 col-sm-6 col-6">
                                                <p className="color-black"> {productInfo.compatibility_details}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-12 col-sm-12">
                                    <div className="image">
                                        {/* <img src={ProductImage} alt="image" /> */}
                                        <OwlCarousel className="ml-feedback-slides owl-carousel owl-theme p-m-10" {...options}>
                                            {productInfo.product_images.map((image, i) => (
                                                <img srcSet={image.srcset} sizes={image.sizes} style={{ "width": "100%", height: "100%", objectFit: "cover", objectPosition: "center center", opacity: 1, transition: "opacity 500ms ease 0s" }} key={i} />
                                            ))}

                                            {/* <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <h3>Steven Smith</h3>
                            <span>CEO at Envato</span>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid ullam harum sit. Accusantium veritatis dolore ducimus illum, cumque excepturi, autem rerum illo amet placeat odit corporis!</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>

                    <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <h3>James Eva</h3>
                            <span>CEO at Envato</span>
                        </div>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquid ullam harum sit. Accusantium veritatis dolore ducimus illum, cumque excepturi, autem rerum illo amet placeat odit corporis!</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div> */}
                                        </OwlCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div className="blog-area ptb-80">
                        <div className="container-fluid padd-2">
                            <div className="row">
                                {/* <div className="col-lg-3 col-md-12">
                <div className="widget-area">
                <div className="widget widget_categories">
                <h5 className="widget-title">On this page</h5>

                <ul>
                    <li><Link to="">Business</Link></li>
                    <li><Link to="">Privacy</Link></li>
                    <li><Link to="">Technology</Link></li>
                    <li><Link to="">Tips</Link></li>
                    <li><Link to="">Uncategorized</Link></li>
                </ul>
            </div>
            </div>
                    </div> */}
                                <div className="col-lg-9 col-md-12 product-detailss">
                                    {/* <div style={{ "whiteSpace": "pre-wrap" }} dangerouslySetInnerHTML={{ __html: productInfo.product_description }}></div> */}
                                    <ReactMarkdown components={{ a: LinkRenderer,img:addImageLazyLoading }} children={productInfo.product_description} />
                                    {productInfo.faqs && productInfo.faqs.length ? <FAQ faqs={productInfo.faqs} /> : ''}
                                    {/* <h3>Title</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <ul>
                                <li><Link to="">Lorem ipsum dolor sit amet</Link></li>
                                <li><Link to="">Lorem ipsum dolor sit amet</Link></li>
                                <li><Link to="">Lorem ipsum dolor sit amet</Link></li>
                                <li><Link to="">Lorem ipsum dolor sit amet</Link></li>
                                <li><Link to="">Lorem ipsum dolor sit amet</Link></li>
                            </ul>
                            <br />
                            <div className="text-center">
                                <Link to="" className="btn btn-primary">
                                    Details Design flow
                                </Link>
                            </div>
                            <br />
                            <h3>Title</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <br />
                            <h3>Title</h3>

                            <ul>
                                <li><Link to="">Lorem ipsum dolor sit amet</Link></li>
                                <li><Link to="">Lorem ipsum dolor sit amet</Link></li>
                                <li><Link to="">Lorem ipsum dolor sit amet</Link></li>
                                <li><Link to="">Lorem ipsum dolor sit amet</Link></li>
                                <li><Link to="">Lorem ipsum dolor sit amet</Link></li>
                            </ul>
                            <br />
                            <h3>FAQs</h3>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                            <h3>FAQs</h3>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h3>FAQs</h3>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h3>FAQs</h3>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h3>FAQs</h3>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            <h4>Q.Question?</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}

                                </div>

                                <div className="col-lg-3 col-md-12">
                                    <div className="card p-3 widget-area is-sticky" id="secondary">
                                        {/* <div className="widget widget_startp_posts_thumb">
                <h3 className="widget-title">Subscribe</h3>
            <div className="widget widget_search">
                <form className="search-form">
                    <label>
                        <input type="search" className="search-field" placeholder="Enter Email Id" />
                    </label>
                    <button type="submit">
                        <Icon.ArrowRight />
                    </button>
                </form>
            </div>
            </div> */}
                                        <Link className="btn buy-btn width-100" to={productInfo.gumroad_purchase_link} target="_blank">
                                            ${productInfo.product_price} BUY
                                        </Link>
                                        {/* <div className="widget widget_startp_posts_thumb">
                                    <h3 className="widget-title">Download Details</h3>
                                    {productInfo.license_details.map((license, j) => (
                                        <div className="form-check" key={j}>
                                            <input type="checkbox" className="form-check-input" id={j} checked={license.price === selectedLicense.price} onChange={() => licenseSelectionEvent(license)} />
                                            <label className="form-check-label">{license.licenseName} - ${license.price}</label>
                                        </div>
                                    ))}
                                    <Link to="" className="btn btn-blue width-100">
                                        Purchase
                                    </Link>
                                </div> */}
                                        {/* <div className="widget widget_startp_posts_thumb">
                                    <h3 className="widget-title">Recent Blog Posts</h3>

                                    <article className="item">
                                        <Link to="/blog-details" className="thumb">
                                            <span className="fullimage cover bg2" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <time>June 21, 2021</time>
                                            <h4 className="title usmall">
                                                <Link to="/blog-details">
                                                    I Used The Web For A Day On A 50 MB Budget
                                                </Link>
                                            </h4>
                                        </div>

                                        <div className="clear"></div>
                                    </article>

                                    <article className="item">
                                        <Link to="/blog-details" className="thumb">
                                            <span className="fullimage cover bg3" role="img"></span>
                                        </Link>
                                        <div className="info">
                                            <time>June 30, 2021</time>
                                            <h4 className="title usmall">
                                                <Link to="/blog-details">
                                                    How To Create A Responsive Popup Gallery?
                                                </Link>
                                            </h4>
                                        </div>

                                        <div className="clear"></div>
                                    </article>
                                </div> */}

                                        <div className="widget widget_tag_cloud">
                                            <h3 className="widget-title">Product Tags</h3>

                                            <div className="tagcloud">
                                                {productInfo.product_tags.map((tag, k) => (
                                                    <Link key={tag.tag_name}>{tag.tag_name}</Link>
                                                ))}
                                                {/* <Link to="/blog-1">Spacle <span className="tag-link-count">(3)</span></Link>
                                        <Link to="/blog-1">Games <span className="tag-link-count">(2)</span></Link>
                                        <Link to="/blog-1">Fashion <span className="tag-link-count">(2)</span></Link>
                                        <Link to="/blog-1">Travel <span className="tag-link-count">(1)</span></Link>
                                        <Link to="/blog-1">Smart <span className="tag-link-count">(1)</span></Link>
                                        <Link to="/blog-1">Marketing <span className="tag-link-count">(1)</span></Link>
                                        <Link to="/blog-1">Tips <span className="tag-link-count">(2)</span></Link> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {productInfo.related_products && productInfo.related_products.length ?
                                    <div className="col-lg-12">
                                        <br /><br />
                                        <br />
                                        <h4>Related Products</h4>
                                        <br />
                                        <div className="row justify-content-center">
                                            {productInfo.related_products.map((product, p) => (
                                                <div className="col-lg-3 col-md-6" key={product.slug}>
                                                    <div className="single-blog-post">
                                                        <div className="blog-image">
                                                            <Link to={`/products/${product.slug}/`}>
                                                                <img src={getImageURL(product.product_image.localFile.name, product.product_image.localFile.extension)} srcSet={product.srcset} size={product.sizes} sizes={product.sizes} style={{ "width": "100%", height: "100%", objectFit: "cover", objectPosition: "center center", opacity: 1, transition: "opacity 500ms ease 0s" }} loading="lazy" alt={product.product_name} />
                                                            </Link>

                                                            <div className="date">
                                                                <Icon.DollarSign />{product.product_price}
                                                            </div>
                                                        </div>

                                                        <div className="blog-post-content">
                                                            <h3 className="font-15">
                                                                <Link to={`/products/${product.slug}/`}>
                                                                    {product.product_name}
                                                                </Link>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {/* Pagination */}
                                            {/* <div className="col-lg-12 col-md-12">
                                <div className="pagination-area">
                                    <nav aria-label="Page navigation">
                                        <ul className="pagination justify-content-center">
                                            <li className="page-item">
                                                <Link to="#" className="page-link">
                                                    Prev
                                                </Link>
                                            </li>

                                            <li className="page-item active">
                                                <Link to="#" className="page-link">1</Link>
                                            </li>

                                            <li className="page-item">
                                                <Link to="#" className="page-link">2</Link>
                                            </li>

                                            <li className="page-item">
                                                <Link to="#" className="page-link">3</Link>
                                            </li> 

                                            <li className="page-item">
                                                <Link to="#" className="page-link">
                                                    Next
                                                </Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div> */}
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>

                </div>

                <Footer />
            </Layout>
        </>
    )
}


export default ProductDetails;
export const query = graphql`
query getProductDetails($slug: String) {
    strapiProducts(slug: {eq: $slug}) {
        best_product
        product_name
        product_slug
        product_price
        compatibility_details
        file_size
        files_included
        id
        last_updated_at
        preview_url
        product_description
        aggregateRating
        total_ratings_count
        product_images {
            localFile{
                name
                extension
              }
        }
        product_tags {
          tag_name
          id
        }
        related_products {
          product_name
          product_price
          product_image {
            localFile{
                name
                extension
              }
          }
          slug
        }
        release_date
        slug
        top_selling
        trending_products {
          product_name
          product_image {
            localFile{
                name
                extension
              }
          }
          product_price
          slug
        }
        short_description
      seo_description
      seo_image {
        localFile{
            name
            extension
        }
      }
      seo_title
      gumroad_purchase_link
      faqs{
        faq_title
        faq_description
        id
      }
      updated_at
      published_at
      categories{
        category_name
      }
    }
  }` 
