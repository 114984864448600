import React from "react";
import Layout from "./_App/layout";
import SEO from "./_App/seo";
import Navbar from "./_App/Navbar";
import Footer from "./_App/Footer";
import PageBanner from './Common/PageBanner';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

const FAQ = ({faqs}) => (
    <div className="faq-area ptb-80">
        
        <div className="">
            <div className="faq-accordion">
                <Accordion allowZeroExpanded preExpanded={['a']}>
                    {faqs.map(faq => (
                        <AccordionItem uuid={faq.id.toString()} key={faq.id}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                <span>{faq.faq_title}</span>
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>{faq.faq_description}</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    ))}
                </Accordion>
            </div>

            {/* <div className="faq-contact">
                <h3>Ask Your Question</h3>
                <form>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <input type="text" placeholder="Name" className="form-control" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                                <input type="email" placeholder="Email" className="form-control" />
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <input type="text" placeholder="Subject" className="form-control" />
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                                <textarea name="message" cols="30" rows="6" placeholder="Message" className="form-control"></textarea>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <button className="btn btn-primary" type="submit">Submit Now!</button>
                        </div>
                    </div>
                </form>
            </div> */}
        </div>
    </div>
)

export default FAQ;